import React, { useState } from 'react'
import { useNavigate, Link, Outlet, NavLink, useLocation, useParams } from "react-router-dom";
import plane from '../thinktrek/images/plane.png'
import Banner from '../thinktrek/images/banner.png'
import Virtual from '../thinktrek/images/virtual_sec.png'
import Object1 from '../thinktrek/images/ob1.png'
import Object2 from '../thinktrek/images/ob2.png'
import Object3 from '../thinktrek/images/ob3.png'
import Object4 from '../thinktrek/images/ob4.png'
import Object5 from '../thinktrek/images/ob5.png'
import GooglePlay from '../thinktrek/images/google-play.png'
import AppStore from '../thinktrek/images/app-store.png'
import Banner1 from '../thinktrek/images/banner_01.png'
import Lakh from '../thinktrek/images/lakh.png'
import Bancross from '../thinktrek/images/ban_cross.png'
import Voucher from '../thinktrek/images/vou.png'
import Women from '../thinktrek/images/v1.png'
import woman1 from '../thinktrek/images/woman1.png'
import Safety from '../thinktrek/images/safety-glasses.png'
import long from '../thinktrek/images/long-wavy-hair-variant.png'
import type1 from '../thinktrek/images/a1.png'
import type2 from '../thinktrek/images/a2.png'
import type3 from '../thinktrek/images/a3.png'
import logo from '../thinktrek/images/logo.png'
import footlogo from '../thinktrek/images/footer_logo.png'

function Home() {
  const location = useLocation();
  const _activeCheck = location.hash;
  return (
    <div>

      <header class="header_sec">
        <div class="container">
          <div class="row">
            <div class="col-sm-12">
              <div class="header_inn">
                <nav class="navbar navbar-expand-lg navbar-light">
                  <div class="container-fluid">
                    <a class="navbar-brand" href="#"><img src={logo} alt="" /></a>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                      <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarSupportedContent">
                      <ul class="navbar-nav me-auto justify-content-end w-100">
                        <li class="nav-item">
                          <a class={(_activeCheck == '#about' || _activeCheck == '') ? `nav-link active` : `nav-link`} aria-current="page" href="#about" >ABOUT US</a>
                        </li>
                        <li class="nav-item">
                          <a class={_activeCheck == '#product' ? `nav-link active` : `nav-link`} href="#product">PRODUCTS</a>
                        </li>
                        <li class="nav-item">
                          <a class={_activeCheck == '#footer' ? `nav-link active` : `nav-link`} href="#footer" >CONTACT US</a>
                        </li>
                      </ul>

                    </div>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </header>


      <section className="banner_sec">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-xxl-4 col-xl-4 col-lg-4 col-md-12">
              <div className="ban_lt">
                <img className="w-100" src={plane} alt="" />
              </div>
            </div>
            <div className="col-sm-12 col-xxl-8 col-xl-8 col-lg-8 col-md-12">
              <div className="ban_rt">
                <h3>WE ARE PASSIONATE FOR <br />CREATING IMMERSIVE <br />GAMING EXPERIENCES.</h3>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about_sec" id='about'>
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="about_inn">
                <h5>ABOUT <span>US</span></h5>
                <div className="row">
                  <div className="col-sm-12 col-xxl-8 col-xl-8 col-lg-8 col-md-12">
                    <div className="about_blog">
                      <p>we are passionate about pushing the boundaries of gaming technology to create immersive experiences that captivate players worldwide. With a dedicated team of innovative engineers, designers, and gaming enthusiasts, we strive to redefine the gaming landscape through cutting-edge products and groundbreaking advancements.</p>
                    </div>
                  </div>
                  <div className="col-sm-12 col-xxl-4 col-xl-4 col-lg-4 col-md-12">
                    <div className="about_img">
                      <img className="w-100" src={type1} alt="" />
                    </div>
                  </div>
                </div>

                <div className="mob_off">
                  <div className="row ">
                    <div className="col-sm-12 col-xxl-4 col-xl-4 col-lg-4 col-md-12">
                      <div className="about_img">
                        <img className="w-100" src={type2} alt="" />
                      </div>
                    </div>
                    <div className="col-sm-12 col-xxl-8 col-xl-8 col-lg-8 col-md-12">
                      <div className="about_blog" style={{ paddingRight: 0, paddingLeft: 100 }}>
                        <p className="text-end">Since our inception, we have remained committed to delivering high-quality gaming solutions that cater to the diverse needs of gamers across all platforms. Our expertise spans across various domains, that seamlessly integrate innovation and entertainment. </p>
                        <p className="text-end">At Thinktrek, we believe that gaming is more than just a hobby—it's a gateway to boundless creativity, endless possibilities, and unforgettable experiences.</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mob_on">
                  <div className="col-sm-12 col-xxl-8 col-xl-8 col-lg-8 col-md-12">
                    <div className="about_blog" >
                      <p className="text-end">Since our inception, we have remained committed to delivering high-quality gaming solutions that cater to the diverse needs of gamers across all platforms. Our expertise spans across various domains, that seamlessly integrate innovation and entertainment. </p>
                      <p className="text-end">At Thinktrek, we believe that gaming is more than just a hobby—it's a gateway to boundless creativity, endless possibilities, and unforgettable experiences.</p>
                    </div>
                  </div>
                  <div className="col-sm-12 col-xxl-4 col-xl-4 col-lg-4 col-md-12">
                    <div className="about_img">
                      <img className="w-100" src={type2} alt="" />
                    </div>
                  </div>

                </div>

                <div className="row">
                  <div className="col-sm-12 col-xxl-8 col-xl-8 col-lg-8 col-md-12">
                    <div className="about_blog">
                      <p>As a company deeply rooted in the gaming community, we understand the importance of fostering meaningful connections and collaborations. That's why we actively engage with gamers, developers, and industry experts to gather insights, solicit feedback, and spark inspiration for our next generation of products. Welcome to the next level of gaming.</p>
                    </div>
                  </div>
                  <div className="col-sm-12 col-xxl-4 col-xl-4 col-lg-4 col-md-12">
                    <div className="about_img">
                      <img className="w-100" src={type3} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="about_inn" id='product'>
        <h5><span>Our</span> Products</h5>
      </div>
      <section className="main_banner" style={{ backgroundImage: `url(${Banner})` }}>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-xxl-6 col-xl-6 col-lg-6 col-md-12">
              <div className="main_ban_lt">
                <img className="w-100 animate__animated animate__fadeInLeft" src={Banner1} alt='' />
              </div>
            </div>
            <div className="col-sm-12 col-xxl-6 col-xl-6 col-lg-6 col-md-12">
              <div className="main_ban_rt">
                <img className="ob2_sec wow animate__animated animate__fadeInTopLeft" src={Object2} alt='' />
                <img className="ob3_sec wow animate__animated animate__fadeInTopRight" style={{ animationDelay: ".85s" }} src={Object3} alt='' />
                {/* <h6 className="wow animate__animated animate__shakeX">PLAY, WIN & SHOP</h6> */}
                <h2 className="wow animate__animated animate__fadeInRight">DAILY WINNINGS</h2>
                <div className="lakh_sec wow animate__animated animate__zoomIn" style={{ animationDelay: ".5s" }}>
                  <img className="ob1_sec" src={Object1} alt='' />
                  <img src={Lakh} alt='' />
                </div>
                <div className="shopping_sec wow animate__animated animate__fadeInUp" style={{ animationDelay: ".95s" }}>
                  <img src={Voucher} alt='' />
                </div>
                <img className="ob4_sec wow animate__animated animate__fadeInBottomLeft" style={{ animationDelay: "1.15s" }} src={Object4} alt='' />
                <img className="ob5_sec wow animate__animated animate__fadeInBottomRight" style={{ animationDelay: ".95s" }} src={Object5} alt='' />
                {/* <div className="play_store_sec wow animate__animated animate__zoomIn" style={{ animationDelay: "1.55s" }}>
                  <img src={GooglePlay} alt='' />
                  <img src={AppStore} alt='' />
                </div> */}
                <p className="knowmore"><a target="_blank" href="https://justgame.in/">Know More</a></p>
                <img className="ban_crs" src={Bancross} alt='' />
              </div>
            </div>
          </div>
        </div>
      </section>


      {/* <section className="betsto_win_sec" style="background: url('images/betstowin.png') no-repeat center center;">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="betstowin_inn">
                <img src="images/betlogo.png" alt=""/>
                 <h4>ONLINE <span>CASINO GAMES</span> PROVIDER</h4>
                 <p>Step into the world of endless entertainment with our wide range of engaging casino games. From classNameic slots to thrilling table & card games, our games are designed to provide an immersive gaming experience for all players</p>


                 <div className="bet_game">
                  <div className="bet_game_in">
                    <img src="images/l1.png" alt=""/>
                    <p>Slots</p>
                  </div>

                  <div className="bet_game_in">
                    <img src="images/l2.png" alt=""/>
                    <p>Table Casinos</p>
                  </div>

                  <div className="bet_game_in">
                    <img src="images/l3.png" alt=""/>
                    <p>Crash Games</p>
                  </div>

                  <div className="bet_game_in">
                    <img src="images/l4.png" alt=""/>
                    <p>Virtual Sports</p>
                  </div>

                  <div className="bet_game_in">
                    <img src="images/l5.png" alt=""/>
                    <p>Poker Software</p>
                  </div>
                 </div>

                 <p className="knowmore"><a href="">KnowMore</a></p>
               </div>
            </div>
          </div>
        </div>
      </section> */}

      <section className="virtual_sec" style={{ backgroundImage: `url(${Virtual})` }}>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-xxl-6 col-xl-6 col-lg-6 col-md-12">
              <div className="virtual_lt" style={{ backgroundImage: `url(${Women})` }}>

              </div>
            </div>
            <div className="col-sm-12 col-xxl-6 col-xl-6 col-lg-6 col-md-12">
              <div className="virtual_rt">
                <h6>AI-POWERED</h6>
                <h5>VIRTUAL</h5>
                <h2>TRY-ON</h2>

                <div className="virtual_btm">
                  <div className="row">
                    <div className="col-sm-4">
                      <div className="virtual_top">
                        <img src={woman1} alt="" />
                      </div>
                      <p>Face Makeup</p>
                    </div>
                    <div className="col-sm-4">
                      <div className="virtual_top">
                        <img src={Safety} alt="" />
                      </div>
                      <p>Eye Glasses</p>
                    </div>
                    <div className="col-sm-4">
                      <div className="virtual_top">
                        <img src={long} alt="" />
                      </div>
                      <p>Hair Makeup</p>
                    </div>

                    <p className="knowmore" ><a style={{ textDecoration: "none" }} href="#">Coming Soon</a></p>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <footer className="footer_sec" id='footer'>
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="footer_in">
                <div className="footer_logo">
                  <img src={footlogo} alt="" />
                </div>
                <div className="row">
                  <div className="col-sm-5">
                    <div className="footer_lt">
                      <div className="footer_top">
                        <h6>DELHI</h6>
                        <div className="footer_btm">
                          <p><span><i className="fa-solid fa-location-dot"></i></span><span>DLF Prime Tower, Unit No. 308, Third Floor, Okhla Phase - 1 New Delhi - 110020, India</span></p>
                          <p><span><i className="fa-solid fa-phone"></i></span><span>Phone : +91 (011) 4070-4088</span></p>
                          <p><span><i className="fa-regular fa-envelope"></i></span><span>Email : info@.thinktrekent.com</span></p>
                        </div>

                      </div>

                      <div className="footer_top mt-5">
                        <h6>KOLKATA</h6>
                        <div className="footer_btm">
                          <p><span><i className="fa-solid fa-location-dot"></i></span><span>Mani Casadona, West Tower, Unit No: 6WS1, 6th floor, Plot No: 11F/04, Action Area: IIF, New Town, Rajarhat, Kolkata- 700156</span></p>
                          <p><span><i className="fa-solid fa-phone"></i></span><span>Phone : +91 (033) 4007-8554</span></p>
                          <p><span><i className="fa-regular fa-envelope"></i></span><span>Email : info@.thinktrekent.com</span></p>
                        </div>

                      </div>
                    </div>
                  </div>
                  <div className="col-sm-3 ps-5">
                    <div className="footer_lt">
                      <div className="footer_top">
                        <h6>OUR LINKS</h6>
                        <div className="footer_btm">
                          <ul>
                            <li><a href="">About Us</a></li>
                            <li><a href="">Products</a></li>
                            <li><a href="">Contact Us</a></li>
                          </ul>
                        </div>
                      </div>

                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="footer_lt">
                      <div className="footer_top">
                        <h6>SUPPORT</h6>
                        <div className="footer_btm support">
                          <form>
                            <div className="foot1">
                              <input type="text" placeholder="Enter Your Name" />
                            </div>
                            <div className="foot1">
                              <input type="text" placeholder="Enter Your Email" />
                            </div>
                            <div className="foot1">
                              <textarea placeholder="Write your message"></textarea>
                            </div>
                            <div className="foot1">
                              <input type="button" value="submit" />
                            </div>
                          </form>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>

              <div className="footer_dwn">
                <p>Copyright © 2024 Thinktrek Entertainment Pvt. Ltd.All Rights Reserved</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Home








